<template>
  <v-container fluid>
    <card :title="$t('models.country.selves')">
      <GridView
        :headers="headers"
        model="country"
        endpoint="/country?expand=region"
      >
        <template v-slot:item._actions="{ item }">
          <v-btn @click="viewCountry(item)" icon small
            ><v-icon small>mdi-eye</v-icon></v-btn
          >
          <v-btn @click="editCountry(item)" icon small
            ><v-icon small>mdi-pencil</v-icon></v-btn
          >
          <v-btn @click="deleteCountry(item)" icon small
            ><v-icon x-small>fa fa-trash</v-icon></v-btn
          >
        </template>
        <template v-slot:item.name="{ item }">
          <CountryFlag :code="item.code" />
          {{ translate(item.name) }}
        </template>
        <template v-slot:item.region.name="{ item }">
          {{ translate(item.region.name) }}
        </template>
      </GridView>
    </card>

    <!-- view dialog -->
    <v-dialog v-model="viewDialog" max-width="600">
      <v-card v-if="country">
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">
              {{ translate(country.name) }}
            </h3>
            <div>{{ country }}</div>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-btn flat color="primary">Close</v-btn>
          <v-btn flat color="primary">Edit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog -->
    <v-dialog v-model="editDialog" max-width="600">
      <Form
        :countryId="editedCountryId"
        v-if="editDialog"
        @save="onDialogSave"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import Form from "@/pages/country/Form";
import formatter from "@/mixins/formatter";
import * as flags from "country-flag-icons/string/3x2";
import Card from "@/components/Card";
import CountryFlag from "@/components/CountryFlag";
import GridView from "@/components/GridView";
import { setPageTitle } from "@/utils/meta";

@Component({
  components: { GridView, CountryFlag, Form, Card },
  mixins: [formatter],
})
export default class CountryIndex extends Vue {
  countries = [];
  viewDialog = false;
  editDialog = false;
  country = null;
  editedCountryId = null;

  created() {
    setPageTitle(this.$i18n.t("models.country.selves"));
  }

  get headers() {
    return [
      { text: "", value: "_actions" },
      {
        text: this.$i18n.t("models.country.self"),
        value: "name",
        sort: this.i18nSort,
      },
      { text: this.$i18n.t("models.country.code"), value: "code" },
      {
        text: this.$i18n.t("models.country.region"),
        value: "region.name",
        sort: this.i18nSort,
      },
    ];
  }

  flag(code) {
    return flags[code];
  }

  viewCountry(country) {
    this.$api.get(`country/${country.id}?expand=region,name:all`).then(res => {
      this.country = res.data;
      this.viewDialog = true;
    });
  }

  editCountry(country) {
    this.editedCountryId = country.id;
    this.editDialog = true;
  }

  deleteCountry(country) {
    this.$root.$confirm
      .open(
        "Delete country",
        "Are you sure you want to delete this country ?",
        {}
      )
      .then(confirm => {
        if (confirm) {
          this.$api
            .delete(`country/${country.id}`)
            .then(() => {
              this.countries.splice(this.countries.indexOf(country), 1);
            })
            .catch(() => {
              alert("Unable to remove country");
            });
        }
      });
  }

  onDialogSave(country) {
    const idx = this.countries.findIndex(p => p.id === country.id);
    if (idx > -1) {
      this.$set(this.countries, idx, country);
    } else {
      this.getCountries();
    }
    this.editDialog = false;
    this.editedCountryId = null;
  }
}
</script>
